// Do not touch: used by webpack to bundle CSS
import styles from './../css/main.css';

// Libraries
import './utils/modernizr-custom-3.5.0'
import $ from 'jquery'
//import Headroom from 'headroom.js'

// Only if one needs to support particular touch interactions
//import TouchEmulator from 'hammer-touchemulator'
//import Hammer from 'hammerjs'

// Detects
import MQ from './utils/mq'

// Polyfills
// Note: arrow functions, const, let, template literals, classes and special characters
// are transformed by babel at compile time
import 'core-js/es6/promise' // instead of import 'babel-polyfill' -> https://stackoverflow.com/a/36350399
import 'core-js/es6/array' // for Array.from
import objectFitImages from 'object-fit-images'

// Other utils
import GDPRElement from './modules/gdpr/gdpr'
import './modules/gdpr/ga'

GDPRElement('.gdpr-ga-state');
GDPRElement('.gdpr-ga-alert');

const mq = MQ([320, 480, 586, 640, 768, 840, 960, 1024, 1280, 1400]); // available breakpoints

checkIfOver320();
$(window).on('resize', checkIfOver320);

function checkIfOver320() {
  if(mq.over320) { // Configure viewports
    console.log('viewport over 320px');
  }
}

objectFitImages();

// ==============================
// Loader
// ==============================
$(document).ready(function() {
  var preloaderFadeOutTime = 500;

  function hidePreloader() {
    var preloader = $('[data-role="preloader"]');
    $('body').attr("state","loaded");
    // preloader.slideUp(preloaderFadeOutTime);
    preloader.addClass('hidden');
  }
  setTimeout(hidePreloader, 4000)
});


// ==============================
// Logo game
// ==============================
// General typer
$(document).ready(function() {
  // List of sentences
  var contentB = [
    "Beginning ",
    "Book ",
    "By ",
    "Barrel ",
    "Battle ",
    "Brotherhood ",
    "Bewust ",
    "Beginning ",
    "Box "
  ];
  var contentO = [
    "Of ",
    "Of ",
    "Other ",
    "Of ",
    "Of ",
    "Of ",
    "Ongehuwde ",
    "Of ",
    "Office "
  ];
  var contentM = [
    "Message",
    "Moon",
    "Means",
    "Monkeys",
    "Mice",
    "Makuta",
    "Moeder",
    "Mission",
    "Mojo"
  ];

  var currentSentence = 0;
  var currentIndex = 0;   // Character number of the current sentence being processed
  var interval; // Holds the handle returned from setInterval

  // Element that holds the text
  var elementB = document.querySelector('[data-type="logogame"] p [letter="B"]');
  var elementO = document.querySelector('[data-type="logogame"] p [letter="O"]');
  var elementM = document.querySelector('[data-type="logogame"] p [letter="M"]');

  // Implements typing effect
  function Type() {
  	// Get substring with 1 characater added
  	var textB =  contentB[currentSentence].substring(0, currentIndex + 1);
    var textO =  contentO[currentSentence].substring(0, currentIndex + 1);
    var textM =  contentM[currentSentence].substring(0, currentIndex + 1);
  	elementB.innerHTML = textB;
    elementO.innerHTML = textO;
    elementM.innerHTML = textM;
    $('[data-type="logogame"] [role="space"]').css('width', 'auto');
  	currentIndex++;

  	// If full sentence has been displayed then start to delete the sentence after some time
  	if(textB === contentB[currentSentence] && textO === contentO[currentSentence] && textM === contentM[currentSentence]) {
  		clearInterval(interval);
      $('[data-type="logogame"] [role="space"]').css('width', 'auto');
  		setTimeout(function() {
  			interval = setInterval(Delete, 80);
  		}, 1500);
  	}
  }

  // Implements deleting effect
  function Delete() {
  	// Get substring with 1 characater deleted
  	var textB =  contentB[currentSentence].substring(0, currentIndex - 1);
    var textO =  contentO[currentSentence].substring(0, currentIndex - 1);
    var textM =  contentM[currentSentence].substring(0, currentIndex - 1);
    elementB.innerHTML = textB;
    elementO.innerHTML = textO;
    elementM.innerHTML = textM;
  	currentIndex--;

  	// If sentence has been deleted then start to display the next sentence
  	if(textB === 'B' && textO === 'O' && textM === 'M') {
      $('[data-type="logogame"] [role="space"]').css('width', '0px');
  		clearInterval(interval);

  		// If current sentence was last then display the first one, else move to the next
  		if(currentSentence == (contentB.length - 1) && currentSentence == (contentO.length - 1) && currentSentence == (contentM.length - 1))
  			currentSentence = 0;
  		else
  			currentSentence++;

  		currentIndex = 0;

  		// Start to display the next sentence after some time
  		setTimeout(function() {
  			interval = setInterval(Type, 100);
  		}, 200);
  	}
  }
  // Start the typing effect on load
  interval = setInterval(Type, 80);
});
// End General typer


// Preloader
$(document).ready(function() {
  // List of sentences
  var contentBPre = [
    "BEGINNING",
    "BOOK",
    "BY",
    "BARREL"
  ];
  var contentOPre = [
    "OF",
    "OF",
    "OTHER",
    "OF"
  ];
  var contentMPre = [
    "MESSAGE",
    "MOON",
    "MEANS",
    "MONKEYS"
  ];

  var currentSentence = 0;
  var currentIndex = 0;   // Character number of the current sentence being processed
  var interval; // Holds the handle returned from setInterval

  // Element that holds the text
  var elementBPre = document.querySelector('[data-type="logogamePreLoad"] p [letter="B"]');
  var elementOPre = document.querySelector('[data-type="logogamePreLoad"] p [letter="O"]');
  var elementMPre = document.querySelector('[data-type="logogamePreLoad"] p [letter="M"]');

  // Implements typing effect
  function TypePre() {
  	// Get substring with 1 characater added
  	var textBPre =  contentBPre[currentSentence].substring(0, currentIndex + 1);
    var textOPre =  contentOPre[currentSentence].substring(0, currentIndex + 1);
    var textMPre =  contentMPre[currentSentence].substring(0, currentIndex + 1);
  	elementBPre.innerHTML = textBPre;
    elementOPre.innerHTML = textOPre;
    elementMPre.innerHTML = textMPre;
    $('[data-type="logogamePreLoad"] [role="space"]').css('width', 'auto');
  	currentIndex++;

  	// If full sentence has been displayed then start to delete the sentence after some time
  	if(textBPre === contentBPre[currentSentence] && textOPre === contentOPre[currentSentence] && textMPre === contentMPre[currentSentence]) {
  		clearInterval(interval);
      $('[data-type="logogamePreLoad"] [role="space"]').css('width', 'auto');
  		setTimeout(function() {
  			interval = setInterval(DeletePre, 80);
  		}, 1500);
  	}
  }

  // Implements deleting effect
  function DeletePre() {
  	// Get substring with 1 characater deleted
  	var textBPre =  contentBPre[currentSentence].substring(0, currentIndex - 1);
    var textOPre =  contentOPre[currentSentence].substring(0, currentIndex - 1);
    var textMPre =  contentMPre[currentSentence].substring(0, currentIndex - 1);
    elementBPre.innerHTML = textBPre;
    elementOPre.innerHTML = textOPre;
    elementMPre.innerHTML = textMPre;
  	currentIndex--;

  	// If sentence has been deleted then start to display the next sentence
  	if(textBPre === 'B' && textOPre === 'O' && textMPre === 'M') {
      $('[data-type="logogamePreLoad"] [role="space"]').css('width', '0px');
  		clearInterval(interval);

  		// If current sentence was last then display the first one, else move to the next
  		if(currentSentence == (contentBPre.length - 1) && currentSentence == (contentOPre.length - 1) && currentSentence == (contentMPre.length - 1))
  			currentSentence = 0;
  		else
  			currentSentence++;

  		currentIndex = 0;

  		// Start to display the next sentence after some time
  		setTimeout(function() {
  			interval = setInterval(TypePre, 100);
  		}, 1500);
  	}
  }
  // Start the typing effect on load
  interval = setInterval(TypePre, 100);
});
// End Preloader



$(window).scroll(function() {
  if($(window).scrollTop() + $(window).height() == $(document).height()) {
    console.log('You reached the bottom');
    $('[data-type="logogame"]').addClass('noShadow');
  } else {
    console.log('No bottom');
    $('[data-type="logogame"]').removeClass('noShadow');
  }
});



// ==============================
// Green Wording
// ==============================
$(document).ready(function() {
  var words = [
  'BOM',
  'FOR',
  'NATURE'
  ], i = 0;
  setInterval(function(){
    $('#changingword').html(words[i=(i+1)%words.length]).css("display", "block");
  }, 1000);
});



// ==============================
// Green
// ==============================
$(window).scroll(function() {

  if ($('body').hasClass('home')) {
    if($(window).scrollTop() > $(window).height() && $(window).scrollTop() + $(window).height() != $(document).height()) {
      $('[data-role="green"]').css('transform', 'scale(1)');
    } else {
      $('[data-role="green"]').css('transform', 'scale(0)');
    }
  } else if ($('body').hasClass('page-id-22')) {
    if($(window).scrollTop() > 10 && $(window).scrollTop() + $(window).height() != $(document).height()) {
      $('[data-role="green"]').css('transform', 'scale(1)');
    } else {
      $('[data-role="green"]').css('transform', 'scale(0)');
    }
  }
  else {
    $('[data-role="green"]').css('transform', 'scale(0)');
  }

  // if ($('body').hasClass('page-template-template-archive')) {
  //   if($(window).scrollTop() > $(window).height() / 2 && $(window).scrollTop() <  $(document).height() - $(window).height()*2) {
  //     $('.marqueGreen').addClass('visible');
  //   } else {
  //     $('.marqueGreen').removeClass('visible');
  //   }
  // }

});

// ==============================
// Desktop Hide Header
// ==============================
$(window).scroll(function() {
  if ($(window).width() >= 1200) {
   if($(window).scrollTop() + $(window).height() == $(document).height()) {
     console.log("bottom!");
     $('header').addClass('hidden');
   } else {
     $('header').removeClass('hidden');
   }
 }
});

// ==============================
// Mobile Hide Header
// ==============================
$(function(){
  if ($(window).width() < 768) {
    var lastScrollTop = 0, delta = 5;
    $(window).scroll(function(event){
      var st = $(this).scrollTop();
      var header = $('header');

      if(Math.abs(lastScrollTop - st) <= delta)
      return;

      if (st > lastScrollTop){
        // downscroll code
        console.log('scroll down');
        header.addClass('mblBehaviour');
        header.removeClass('mblShown');
      } else {
        // upscroll code
        console.log('scroll up');
        header.addClass('mblShown');
      }
      lastScrollTop = st;

      if($(window).scrollTop() <= 0) {
        console.log('You reached the TOP');
        header.removeClass('mblBehaviour');
      }
    });
  }
});

// ==============================
// Home Video Preview Controls
// ==============================
var figure = $('[data-type="prjct-prev"].video_prev').hover(hoverVideo, hideVideo);
function hoverVideo(e) {
  console.log('Hover prev play');
  $('video', this).get(0).play();
}
function hideVideo(e) {
  $('video', this).get(0).pause();
}


// $('[data-type="video-prev"]').on('mouseenter', function () {
//     console.log('Your are over this video!');
//     var obj = $(this);
//     obj.removeAttr("poster");
// })

// ==============================
// Archive Video Preview Controls
// ==============================
var figure = $('[data-type="prjct-prev"]').hover(hoverVideoArchive, hideVideoArchive);
function hoverVideoArchive(e) {
  console.log('Hover prev play');
  $('video', this).get(0).play();
  $('img', this).css('opacity', '0');
}
function hideVideoArchive(e) {
  $('video', this).get(0).pause();
}

// ==============================
// Single project video
// ==============================
$(document).on('click', '.play-icon', function(e) {
  var par = $(this).parent('.video-control');
  var videoWrapper = par.prev('.js-videoWrapper');
  var iframe = videoWrapper.find('.prjctVideo');

  iframe.css('visibility', 'visible');
  $(this).css('display', 'none');
  var symbol = iframe[0].src.indexOf("?") > -1 ? "&" : "?";
  //modify source to autoplay and start video
  iframe[0].src += symbol + "autoplay=1&controls=1";
});


// ==============================
// Vimeo
// ==============================
// let Vimeo = require('vimeo').Vimeo;
// let client = new Vimeo("{2d4469ee7abdd8175541f775d30221cddcf8f6d9}", "{UNUTqqS74ZgbUty8XkKXj+0BnTiJpAqw4LZrSgoXqPLJq8/TzXqQI3Lv/31To8cVwDaiSAwu+YR7kFXELl9CWVVP9BKDoyLzhw/R0QQTfa6eTQJDGC0Qe7QNco2Oe+7M}", "{986465d2aef77f29c870d8a485b16c57}");
